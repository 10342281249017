import BaseModel from "../../../common/baseModel";

export default class WaybillModel extends BaseModel {

  name = "运单";
  api = "/shipment/waybill";
  detailApi = "/shipment/waybillDetail";

  quickEdit = false;

  formGrouping = [
    {label: "路线信息", columns: ["consignor", "consignorBase", "startArea", "customer", "unloadAreas"]},
    {label: "承运信息", columns: ["driver", "truckNo", "loadUnloadType", "truckLength", "containerType", "tempTruckNo", "additionalPay"]},
    {label: "付款信息", columns: ["totalAmount", "prePay", "arrivePay", "receivePay", "oilCardPay", "factoryOilPay", "wanjinOilPay",  "deposit", "insuranceFee", "otherFee", "returnBillNo"]}
  ];

  fields() {
    return [
      {
        key: "details",
        visibleInForm: false,
        visibleInGrid: false,
        visibleInDetail: false
      },
      {
        key: "startArea",
        inputControl: "region"
      },
      {
        key: "unloadAreas",
        inputControl: "region",
        inputControlProps: {
          multiple: true
        }
      },
      {
        key: "loadUnloadType",
        inputControl: "select"
      },
      {
        key: "returnBillNo",
        placeholder: "多个用英文逗号分割",
        inputControl: "select",
        inputControlProps: {
          multiple: true
        }
      },
      {
        key: "unloadAreasCount",
        visibleInForm: false
      },
      {
        key: "symbol",
        filterable: {
          key: "symbol|outBillNo"
        }
      },
      {
        key: "status",
      },
      {
        key: "consignorBase",
        inputControl: "select",
        labelField: 'name',
        valueField: 'id'
      },
      {
        key: "driver",
        remoteQueryField: "name|mobile|symbol",
        getDisplay(row) {
          return row?.fullInfo;
        },
        filterable: {
          type: "string",
          fuzzy: true,
          key: "driverName|driverMobile|carrierName"
        },
        placeholder: "输入姓名或手机号搜索"
      },
      {
        key: "unloadAreas",
      },
      {
        key: "arrivePay",
        readonly: true
      },
    ];
  }

}
