import DataStructure from "./dataStructure";
import _ from "lodash";

export default class BaseModel {

    static instance = null;

    api;

    formGrouping = false;

    fieldsMap = {};

    defaultFields = [
        {
            key: "createdBy",
            foreignKey: "name",
            label: "创建人",
            visibleInForm: false,
            type: "FOREIGN"
        },
        // {
        //     key: "createdDate",
        //     label: "创建于",
        //     visibleInForm: false,
        //     type: "DATE",
        //     filterable: {
        //         type: "date"
        //     }
        // },
        {
            key: "modifiedBy",
            foreignKey: "name",
            label: "修改人",
            visibleInForm: false,
            type: "FOREIGN"
        },
        {
            key: "modifiedDate",
            label: "最后修改",
            visibleInForm: false,
            type: "DATE",
            filterable: {
                type: "date"
            }
        }
    ];

    fields() {
        return [];
    }

    quickEdit = true;

    getGridColumns() {
        let result = (this.getFields() || []).concat(this.defaultFields);

        result.forEach(field => {
            field.sortable = field.sortable === true || field.type === "DATE";
        });

        result = result.filter(item => {
            return item.visibleInGrid !== false && item.key;
        });

        return _.orderBy(result, item => {
            let index = item.gridColumnIndex || item.columnIndex;
            return 99 + ((isNaN(index) || index === 0) ? 0 : index);
        }, "desc");
    }

    getFormColumns() {
        let result = this.getFields() || [];

        result.forEach(field => {
            field.columnWidth = field.columnWidth || this.columnWidth || 24;
        })

        result = result.filter(field => {
            return field.systemGenerated !== true
                && field.editable !== false
                && field.visibleInForm !== false;
        });

        return _.orderBy(result, item => {
            let index = item.formColumnIndex || item.columnIndex;
            return 99 + ((isNaN(index) || index === 0) ? 0 : index);
        }, "desc");
    }

    getFields(returnMap) {

        let result = this.fields();

        const dsInstance = DataStructure.getInstance();
        const ds = dsInstance.get(this.api);

        if(this.api) {
            let exists = [],
                existsMap = {};
            ds.forEach((field, index) => {
                exists.push(field.key);
                existsMap[field.key] = index;
            });

            result.forEach(field => {
                if(exists.indexOf(field.key) >= 0) {
                    ds[existsMap[field.key]] = Object.assign(ds[existsMap[field.key]], field);
                } else {
                    ds.push(field);
                }
            });


            ds.forEach(field => {
                if(typeof field.getStaticStore === "function" && !field.staticStore) {
                    field.staticStore = field.getStaticStore();
                }

                if(field.filterable === undefined && field.virtual) {
                    field.filterable = false;
                }

                if(!field.inputControl) {
                    switch(field.type) {
                        case "FOREIGN":
                            field.inputControl = "combo";
                            break;
                        case "ENUM":
                            field.inputControl = "select";
                            break;
                        case "BOOLEAN":
                            field.inputControl = "switch";
                            break;
                        case "DATE":
                            field.inputControl = "date";
                            break;
                        case "NUMBER":
                        case "INTEGER":
                        case "BIGDECIMAL":
                            field.inputControl = "text";
                            field.inputType = "number";
                            break;
                        default:
                            field.inputControl = "text";
                    }
                }

                switch (field.type) {
                    case "FOREIGN":
                        field.enableRemoteQuery = false;

                        field.filterable = field.filterable !== undefined ? field.filterable : {
                            type: "combo",
                            fuzzy: true
                        };

                        field.remoteQuery = field.remoteQuery || function(keyword, callback, app) {
                            keyword = keyword ? keyword.trim() : "";

                            if(!keyword && !field.preload) {
                                if(typeof callback === "function") {
                                    callback({
                                        total: 0,
                                        items: []
                                    });
                                }
                                return Promise.resolve({
                                    total: 0,
                                    items: []
                                });
                            }

                            let params = {
                                page: 1,
                                limit: 30
                            }

                            if(field.filterable.fuzzy !== false && keyword.length) {
                                keyword = "%" + keyword + "%";
                            }

                            params[field.remoteQueryField || "name"] = keyword;

                            return app.axios.get(field.foreignApi, {
                                params: Object.assign(params, field.defaultQueryParams || {})
                            }).then((res) => {
                                if (typeof field.optionsFormat === 'function') {
                                    field.options = field.optionsFormat(res.items || []);
                                } else {
                                    field.options = res.items;
                                }
                                if(typeof callback === "function") {
                                    callback(res);
                                }

                                return res;
                            });
                        };

                        if(!field.preload) {
                            field.enableRemoteQuery = true
                        }
                        break;
                    case "ENUM":
                        field.options = field.options || field.staticStore || [];
                        field.filterable = field.filterable !== undefined ? field.filterable : {
                            type: "select"
                        };
                        break;
                    case "BOOLEAN":
                        field.options = field.options || field.staticStore || [];
                        field.filterable = field.filterable !== undefined ? field.filterable : {
                            type: "select"
                        };
                        break;
                    case "DATE":

                        field.filterable = field.filterable !== undefined ? field.filterable : {
                            type: "date"
                        };
                        break;
                    case "NUMBER":
                    case "BIGDECIMAL":
                        field.filterable = field.filterable !== undefined ? field.filterable : {
                            type: "number",
                            fuzzy: false
                        };
                        field.showSummary = true;
                        break;
                    default:
                        field.filterable = field.filterable !== undefined ? field.filterable : {
                            type: "string",
                            fuzzy: true
                        };
                        break;
                }

                this.fieldsMap[field.key] = field;
            });

            this.defaultFields.forEach(item => {
                this.fieldsMap[item.key] = item;
            });

            result = ds;
        }

        return returnMap === true ? this.fieldsMap : result;
    }

    static getInstance() {

        if(this.instance && DataStructure.getInstance().get(this.api).length) {
            return this.instance;
        }


        this.instance = new this;

        this.instance.getFields();

        if(this.instance.formGrouping) {
            let columns = _.flatten(Array.from(this.instance.formGrouping, group => group.columns)),
                other = {
                    label: "其他",
                    columns: []
                };

            Object.keys(this.instance.fieldsMap).forEach(field => {
                if(columns.indexOf(field) < 0 && this.instance.fieldsMap[field].visibleInForm !== false) {
                    other.columns.push(field);
                }
            });
            if(other.columns.length) {
                this.instance.formGrouping.push(other);
            }

        }

        // console.log(this.instance);

        return this.instance;
    }

    getApi() {
        return this.api;
    }

}
